<template>
  <div class="body">
    <UnauthHeader />
    <!-- banner部分 -->
    <div class="topBanner">
      <h1 class="bannerTitle">宽带网络+健康乡村应用试点注册管理系统</h1>
      <div class="toSignBtn" @click="jump">立即报名<img src="@/assets/first-page/go.svg" class="go1" alt=""/><img
          src="@/assets/first-page/go2.svg" class="go2" alt=""/></div>
    </div>
    <!-- 项目说明 -->
    <div class="projectInfo">
      <div class="projectCenter">
        <div class="proLeft">
          <div class="proName">项目背景</div>
          <img class="title" src="@/assets/first-page/title.png" alt="">
          <div class="proMes">
            为深入贯彻落实党的二十大精神，落实《“十四五”信息通信行业发展规划》《关于进一步深化改革促进乡村医疗卫生体系健康发展的意见》等文件要求，充分发挥电信普遍服务网络建设成效，积极运用新一代信息通信技术手段，持续提升农村地区卫生健康服务能力，巩固拓展健康扶贫成果同乡村振兴有效衔接。工业和信息化部、国家卫生健康委决定组织开展“宽带网络+健康乡村”应用试点项目（第一批）申报工作。
          </div>
          <div class="infoLink" @click="openNewPage">
            <img src="@/assets/first-page/file.svg" alt="">
            宽带网络+健康乡村应用试点工作方案
          </div>
          <div class="viewInfo" @click="downloadFile">下载文档</div>
        </div>
        <img class="proRight" src="../../assets/first-page/proRight.webp" alt=""/>

      </div>
    </div>
    <!-- 说明 -->
    <div class="questionInform">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;">申报方向</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">

        <div class="questionUl">
          <div class="questionLi">
            <div class="questionIco">
              <img src="../../assets/first-page/ycdbIco.png" alt=""/>
            </div>
            <div class="questionMes">
              <div class="qmName">远程医疗服务</div>
              <div class="qmTxt">
                帮扶医院和上级医院依托千兆光网、5G等技术推动远程诊疗向基层定点县医院、乡镇卫生院、村卫生室延伸，围绕乡村地区常见病、多发病、重大疾病，以及“三区三州”等地区包虫病、先心病、结核病、大骨节病等多发疾病，开展远程会诊、远程影像诊断、远程病理诊断、远程超声诊断、远程手术指导、远程查房、在线培训等工作，提高规范化诊疗水平，确定相应的医疗质量管理机制。
              </div>
            </div>
          </div>

          <div class="questionLi">
            <div class="questionIco">
              <img src="../../assets/first-page/wlxyIco.png" alt=""/>
            </div>
            <div class="questionMes">
              <div class="qmName">县域医共体信息化建设</div>
              <div class="qmTxt">
                乡村地区紧密型县域医共体依托千兆光网、5G等宽带网络，运用云计算、大数据、隐私保护计算等技术，建立县域卫生健康信息共享平台，整合优化县域影像、心电、病理诊断、医学检验等中心资源配置，实现基层检查、上级诊断和区域内互认。
              </div>
            </div>
          </div>

          <div class="questionLi">
            <div class="questionIco">
              <img src="../../assets/first-page/ydxcIco.png" alt=""/>
            </div>
            <div class="questionMes">
              <div class="qmName">移动乡村巡诊</div>
              <div class="qmTxt">
                帮扶医院、上级医院或其他单位依托5G网络、移动诊疗车构建移动巡诊工作站，结合远程诊疗设备、远程检验设备、智能辅助决策系统等，为乡村地区群众提供远程门诊、智能疾病筛查、智能检验检测、远程诊断、远程会诊等服务。
              </div>
            </div>
          </div>
        </div>
        <div class="questionUl">
          <div class="questionLi">
            <div class="questionIco">
              <img src="../../assets/first-page/wlcyIco.png" alt=""/>
            </div>
            <div class="questionMes">
              <div class="qmName">网络村医助手</div>
              <div class="qmTxt">
                医疗卫生机构、企业等依托千兆光网、5G等宽带网络，结合临床决策支持系统（CDSS）、检测检验设备、健康检测设备、人工智能、语音识别、数字人等技术，协助乡村医生为乡村地区群众提供家庭医生签约、智能检验检测、慢性病管理、健康咨询、中医干预等服务，重点做好心脑血管疾病、糖尿病、结核病、癌症、慢性呼吸系统疾病、严重精神障碍等慢病患者的网络化规范管理。
              </div>
            </div>
          </div>

          <div class="questionLi">
            <div class="questionIco">
              <img src="../../assets/first-page/wlcyjyIco.png" alt=""/>
            </div>
            <div class="questionMes">
              <div class="qmName">网络村医教育培训</div>
              <div class="qmTxt">
                医疗卫生机构、企业等依托千兆光网、5G等宽带网络，结合人工智能、虚拟现实/增强现实、数字人等技术，构建网络化、数字化的村医医学教育培训系统，开展疑难杂症及重大疾病病例探讨交流，通过远程教育手段推广普及实用型适宜技术和药品。
              </div>
            </div>
          </div>


          <div class="questionLi">
            <div class="questionIco">
              <img src="../../assets/first-page/wlggIco.png" alt=""/>

            </div>
            <div class="questionMes">
              <div class="qmName">网络公共卫生防护</div>
              <div class="qmTxt">
                医疗卫生机构等单位依托千兆光网、5G等宽带网络，完善农村地区新冠病毒感染、禽流感等传染病智能预警和远程医疗服务机制，基于多源数据及时掌握和动态分析疾病发生趋势及传染病疫情信息，开展传染病远程监测和防治，加强流行病智能监测和防控，提高农村突发公共卫生事件预警与应急响应能力。
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
    <!-- 申请范围 -->
    <div class="applyRange">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;color: #FFFFFF;">申报条件</div>
        <img class="title" style="margin: 0 auto;" src="@/assets/first-page/titles.png" alt="" />
        <div class="applyRangeUl">
          <div class="applyRangeLi">
            申报单位须为在中华人民共和国境内注册、具有独立法人资格的企事业单位或地方人民政府及其组成部门。
          </div>
          <div class="applyRangeLi">
            各单位可单独或组成联合体申报试点项目，鼓励地方政府、医疗卫生机构、企业、高校、科研院所等单位共同参与，鼓励东西部协作地区联合申报试点项目。以联合体方式申报时，需明确1家单位为牵头单位。
          </div>
          <div class="applyRangeLi">
            各单位可牵头申报1个项目，联合申报不超过6个项目。每个项目仅可选择1个试点方向。
          </div>
          <div class="applyRangeLi">
            申报单位需承诺能够在指定期限内完成任务。
          </div>
        </div>
      </div>
    </div>

    <!-- 组织与安排 -->
    <div class="relatedInfo">
      <div class="proName" style="text-align: center;">相关信息</div>
      <img class="title" style="margin: 0 auto;" src="@/assets/first-page/title.png" alt="" />
      <!-- 工作流程 -->
      <div class="workFlow">
        <h3>工作流程</h3>
        <div class="workFlowItems">
          <div class="workFlowItem">
            <img src="@/assets/first-page/workFlowItem1.png" alt="" /><span>1</span>
            <div>
              <h4>项目申报</h4>
              单位在线填写申请和提交申报材料
            </div>
          </div>
          <img src="@/assets/first-page/arrow.png" alt="" />
          <div class="workFlowItem">
            <img src="@/assets/first-page/workFlowItem2.png" alt="" /><span>2</span>
            <div>
              <h4>单位推荐</h4>
              地方主管部门推荐优秀申报单位与项目
            </div>
          </div>
          <img src="@/assets/first-page/arrow.png" alt="" />
          <div class="workFlowItem">
            <img src="@/assets/first-page/workFlowItem3.png" alt="" /><span>3</span>
            <div>
              <h4>单位遴选</h4>
              组织评审初评优秀申报单位与项目
            </div>
          </div>
          <img src="@/assets/first-page/arrow.png" alt="" />
          <div class="workFlowItem">
            <img src="@/assets/first-page/workFlowItem4.png" alt="" /><span>4</span>
            <div>
              <h4>项目建设</h4>
              入围单位按实施方案开展试点项目建设
            </div>
          </div>
          <img src="@/assets/first-page/arrow.png" alt="" />
          <div class="workFlowItem">
            <img src="@/assets/first-page/workFlowItem5.png" alt="" /><span>5</span>
            <div>
              <h4>项目评价</h4>
              组织开展评价工作并发布典型试点项目
            </div>
          </div>
        </div>
      </div>
      <!-- 申报日期 -->
      <div class="applyDate">
        <h3>申报日期</h3>
        <div>
          <img src="@/assets/first-page/canlender.png" alt="">
          <div>
            <h4>2023年9月30日（申报截止日期）</h4>
            逾期不予受理
          </div>
        </div>
      </div>
    </div>
    <!-- 组织架构 -->
    <div class="organWidth">
      <div class="projectCenter">
        <div class="organStruct">
          <h3>组织架构</h3>
          <div style="justify-content: normal;">
            <div>
              <h4>组织单位</h4>
              <div>工业和信息化部</div>
              <div style="margin-bottom: 6px;">国家卫生健康委</div>
              <!-- <div>国家乡村振兴局</div> -->
            </div>
            <div style="margin-left: 268px;">
              <h4>支撑单位</h4>
              <div>中国信息通信研究院</div>
              <div style="margin-bottom: 6px;">国家卫生健康委统计信息中心</div>
              <div>中国人口与发展研究中心</div>
            </div>
            <div style="margin-left: 180px;">
              <h4>技术支持</h4>
              <div>中国信息通信研究院云计算与大数据研究所</div>
              <div>工业互联网创新中心（上海）有限公司</div>
            </div>
          </div>
        </div>
        <div class="organStruct">
        <h3>申报咨询</h3>
        <div>
          <div>
            <h4>中国信息通信研究院</h4>
            <div class="nameIco">冯天宜 </div>
            <div class="telIco" style="white-space: nowrap;margin-bottom: 6px;">18810626607</div>
            <div class="nameIco" style="margin-bottom: 6px;">武雅文 </div>
            <div class="telIco">18310943365</div>
          </div>
          <div>
            <h4>国家卫生健康委统计信息中心</h4>
            <div class="nameIco">梁艺琼</div>
            <div class="telIco">010-68791646</div>
          </div>
          <div>
            <h4>中国人口与发展研究中心</h4>
            <div class="nameIco">陈佳鹏</div>
            <div class="telIco">010-62128015</div>
          </div>
          <div>
            <h4>工业互联网创新中心</h4>
            <div class="nameIco">孙圳</div>
            <div class="telIco">021-68866370</div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div>
    </div>
    <div class="footerModule">Copyright © 2018-2022 中国信息通信研究院 版权所有  |  <a href="https://beian.miit.gov.cn/"
        style="color:rgba(255,255,255,0.6);text-decoration: none;">沪ICP备17037709号-3</a></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import UnauthHeader from '@/components/header/UnauthHeader.vue';
import { downloadFileSimple, getToken, getUserRole } from '@/utils';
import { download_files } from '@/store/config';
export default defineComponent({
  name: 'ProjectSignUp',
  components: {
    UnauthHeader,
  },
  data() {
    return {
      role: '',
      token: '',
      isEnd: false,
      download_url: download_files.sign_up_file,

    };
  },
  methods: {
    openNewPage() {
      window.open('https://wap.miit.gov.cn/jgsj/txs/wjfb/art/2023/art_fdb60446250241418fc20e85b35b5818.html', '_blank')
    },
    downloadFile() {
      // 关于组织开展2023年“宽带网络+健康乡村”应用试点项目申报工作的通知.docx notice.docx
      downloadFileSimple('/notice.docx', '关于组织开展2023年“宽带网络+健康乡村”应用试点项目申报工作的通知.docx')
      // window.open('https://inos.3incloud.com/public/xcjk/%E5%85%B3%E4%BA%8E%E7%BB%84%E7%BB%87%E5%BC%80%E5%B1%952023%E5%B9%B4%E2%80%9C%E5%AE%BD%E5%B8%A6%E7%BD%91%E7%BB%9C+%E5%81%A5%E5%BA%B7%E4%B9%A1%E6%9D%91%E2%80%9D%E5%BA%94%E7%94%A8%E8%AF%95%E7%82%B9%E9%A1%B9%E7%9B%AE%E7%94%B3%E6%8A%A5%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.docx');
    },
    jump() {
      if (this.token) {
        if (this.role === 'ROLE_USER') {
          this.$router.push('/proj-apls/center/1');
        } else if (
          this.role === 'ROLE_PLATFORM_ADMIN' ||
          this.role === 'ROLE_PLATFORM_USER'
        ) {
          //平台管理员或超级管理员
          this.$router.push('/admin/user/registered/list/1');
        } else if (
          this.role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
          this.role === 'ROLE_INSTITUTION_ADMIN' ||
          this.role === 'ROLE_INSTITUTION_USER'
        ) {
          //初审单位用户或管理员
          this.$router.push('/proj-mgmt/list/1');
        } else if (this.role === 'ROLE_EXPERT') {
          this.$router.push('/proj-review');
        }
      } else {
        this.$router.push(`/auth/login`);
      }
    },
  },
  created() {
    this.token = getToken();
    this.role = getUserRole();
    if (1639583999000 > new Date().getTime()) {
      this.isEnd = false;
    } else {
      this.isEnd = true;
    }
  },
});
</script>

<style scoped>
.telIco::before{
  content:"";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("../../assets/first-page/telIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top:2px;
}
.telIco{
  padding:0 0 0 26px;
  height: auto;
  overflow: hidden;
  position: relative;
}
.nameIco::before{
  content:"";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("../../assets/first-page/nameIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 2px;
}
.nameIco{
  padding:0 0 0 26px;
  height: auto;
  overflow: hidden;
  position: relative;
}
.organWidth {
  width: 100%;
  min-height: 100px;
  height: auto;
  overflow: hidden;
  background-color: #FAFAFA;
}

.applyRangeLi::before {
  content: "";
  display: block;
  width: 18px;
  height: 14px;
  background-image: url("../../assets/first-page/applyIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 5px;
}

.applyRangeLi {
  padding: 0 0 20px 30px;
  height: auto;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  color: #ffffff;

}

.applyRangeUl {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 60px;
}

.qmTxt {
  width: 274px;
  height: auto;
  overflow: hidden;
  color: #666666;
  font-size: 14px;
  line-height: 24px;
}

.qmName {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 22px;
}

.questionIco {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 32px;
}

.questionIco img {
  display: block;
  width: 32px;
  height: 32px;
  margin: 16px !important;
}

.questionMes {
  padding: 0 0 0 84px;
  height: auto;
  overflow: hidden;
}

.questionLi {
  width: 400px;
  height: auto;
  overflow: hidden;
  float: left;
  position: relative;
  margin-bottom: 60px;
}

.questionUl {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.proMes {
  padding: 40px 0 30px 0;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
}

.title {
  display: block;
  width: 40px;
  height: 6px;
}

.proName {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  padding: 60px 0 18px 0;
}

.proLeft {
  width: 624px;
  height: 499px;
  float: left;
}

.proRight {
  position: absolute;
  right: 0;
  top: 0;
  width: 720px;
  height: 499px;
  display: block;
}

.projectCenter {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.body {
  min-width: 1300px;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
  user-select: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  color: #000000;
  font-weight: normal;
}

.topBanner {
  height: 460px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
  background: url('@/assets/first-page/banner.webp') 0 0 / cover;
  padding-top: 161px;
}

h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 41px;
  color: #fff;
}

.toSignBtn {
  margin: auto;
  width: 140px;
  line-height: 44px;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  cursor: pointer;
}

.toSignBtn img {
  margin-left: 10px;
}

.go2 {
  display: none;
}

.toSignBtn:hover {
  color: #0077FF;
  background: #FFFFFF;
}

.toSignBtn:hover .go1 {
  display: none;
}

.toSignBtn:hover .go2 {
  display: inline-block;
}

.projectInfo {
  height: 499px;
  margin: 0 auto;
  background: url('@/assets/first-page/projectInfo.webp') center / auto no-repeat;
}

.projectInfo h2 {
  margin-top: 0;
}

.infoContent+.infoContent {
  margin-top: 12px;
}

h2 {
  color: #000000;
  line-height: 45px;
  margin: 0px 0px 18px;
  font-size: 32px;
  text-align: center;
}

h2+img {
  width: 40px;
  display: block;
  margin: 0px auto 40px;
}

.infoLink {
  color: #2164B1;
  line-height: 20px;
  margin: 0px auto 40px auto;
}

.infoLink img {
  width: 14px;
  margin-right: 8px;
  vertical-align: middle;
}

.viewInfo:hover {
  border: 1px solid #288CFF;
  color: #288CFF;
}

.viewInfo {
  width: 128px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #0077FF;
  color: #0077FF;
  text-align: center;
  cursor: pointer;
}

.questionInform img:first-of-type {
  margin: 0 auto 40px;
}

.questionInform img.leftImg {
  width: 480px;
  margin-left: 0;
  margin-right: 0px;
  margin-bottom: 0px;
}

.questionInform {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-bottom: 20px;
  background: #FAFAFA;
}

.infoListCon {
  display: flex;
  justify-content: space-between;
}

.questionInform .infoList {
  width: 676px;
}

.infoList h3 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  margin: auto;
}

.infoList h3:after {
  content: '';
  width: 0;
  overflow: hidden;
}

.infoList div div {
  margin: 10px 0 30px;
  display: inline-block;
}

.infoList div:last-of-type div {
  margin-bottom: 0px;
}

.questionInform h2,
.applyCondition h2 {
  text-align: center;
}

.applyCondition img {
  margin: 0 0 40px 0;
}

.targetContent {
  width: 600px;
  float: right;
}

.applyRange {
  width: 100%;
  height: 408px;
  background: url("@/assets/first-page/applyRange.webp") center no-repeat;
  background-size: cover;
}

.rangeContent {
  margin-top: 60px;
  /* display: flex; */
  /* justify-content: space-between; */
  color: #666666;
  margin-right: -39px;
}

.rangeContent>div {
  width: 374px;
  min-height: 487px;
  background: #fff;
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.06);
  float: left;
  margin-right: 39px;
  overflow: hidden;
  transition: all 0.3s linear;
}

.rangeContent>div:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 119, 255, 0.15);
  border: 1px solid #0077FF;
}

.rangeContent>div:hover .rangeTitle+div {
  -webkit-line-clamp: 30;
}

.rangeTitle+div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.rangeContent img {
  width: 374px;
  height: 280px;
}

.rangeContent img+div {
  padding: 24px;
}

.rangeTitle {
  color: #333333;
  line-height: 25px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
}

h3 {
  margin: 24px auto 14px;
  text-align: left;
  color: #333333;
  line-height: 25px;
  font-weight: bold;
  font-size:22px;
}

.applyCondition {
  padding: 60px calc(50% - 600px) 0 calc(50% + 80px);
  /* background: url('@/assets/first-page/applyConditionL.png') 0 0 / 50% 100% no-repeat,
    url('@/assets/first-page/applyConditionR.png') 50% 0 / 100% 100% no-repeat; */
  height: 589px;
}

.applyCondition h2 {
  color: #fff;
  text-align: left;
}

.conditionText {
  margin-top: 58px;
  color: #FFFFFF;
  line-height: 26px;
  font-size: 16px;
  position: relative;
  width: 530px;
}

.conditionText::before {
  content: attr(data_index);
  width: 68px;
  line-height: 68px;
  background: #FFFFFF;
  color: #0077FF;
  font-size: 32px;
  border-radius: 50%;
  top: 50%;
  margin-top: -34px;
  position: absolute;
  text-align: center;
  left: -115px;
}

.conditionText:first-of-type {
  margin-bottom: 65px;
}

.conditionText:last-of-type {
  margin-top: 78px;
}

.relatedInfo {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  color: #666666;
  line-height: 22px;
  padding-bottom: 60px;
}

h3 {
  margin: 60px auto 30px;
  text-align: left;
  position: relative;
}

h3::after {
  content: ' ';
  position: absolute;
  width: 64px;
  height: 12px;
  background: url('@/assets/first-page/h3.png') 0 0 / cover;
  top: 50%;
  margin-top: -6px;
  margin-left: 14px;
}

.workFlowItems {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.workFlowItem img {
  width: 40px;
}

.workFlowItem span {
  font-size: 100px;
  color: #F3F3F3;
  line-height: 140px;
}

.workFlowItems>img {
  width: 63px;
  height: 10px;
  margin: 84px 55px auto 24px;
}

h4 {
  margin-bottom: 19px;
  color: #333333;
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
}

.applyDate>div {
  display: flex;
  align-items: flex-start;
}

.applyDate img {
  width: 32px;
  margin-right: 19px;
}

.applyDate h4 {
  margin-bottom: 8px;
}

.organStruct {
  margin-bottom: 60px;
}

.organStruct>div {
  display: flex;
  justify-content: space-between;
  color: #666666;
  line-height: 22px;
}

.organStruct h4 {
  margin-bottom: 18px;
}

.organStruct h4+div {
  margin-bottom: 6px;
}

.support {
  color: #333333;
  font-size: 16px;
  line-height: 22px;
}

.support h4 {
  margin-bottom: 30px;
}

.support>div {
  margin-bottom: 16px;
}

.applyConsult>div {
  display: flex;
}

.business,
.technique {
  width: 300px;
  height: 96px;
}

.widthMax {
  width: 380px;
}

.businessTel {
  display: flex;
  justify-content: space-between;
}

.applyConsult h4 {
  margin-bottom: 22px;
  min-height: 44px;
}

.business {
  margin-right: 96px;
}

.businessTel {
  color: #666666;
  line-height: 22px;
}

.applyConsult img {
  width: 18px;
  vertical-align: -3px;
  margin-right: 8px;
}

.mb14 {
  margin-bottom: 14px;
}

.footerModule {
  width: 100%;
  height: 80px;
  background: #33334F;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  line-height: 80px;
}</style>

