import axios from 'axios';
import { BASE_URL } from './ENV';
import router from '@/router/router';
import { ElMessage } from 'element-plus';
import { getRefreshToken, getToken } from '@/utils';

const baseURL = BASE_URL as string;

const http = axios.create({
  baseURL,
  // withCredentials: true,
  // timeout:6000,
  // timeoutErrorMessage: `timeout`,
});

http.interceptors.request.use((config) => {
  const headers = Object.assign({}, config.headers, {
    Authorization: getToken(),
  });
  config.headers = headers;
  return { ...config };
});
// let isRefreshing = false, refreshPromise = null! as Promise<any>;
http.interceptors.response.use(
  (res) => {
      console.log(100, res)
    return res;
  },
  async (err) => {
      console.log(200, err)
    if (err.request.responseURL.indexOf('/authorize/token/refresh') !== -1) return Promise.reject(err);
    if (err.response.status === 401) {
    //   console.log('==1==>', getToken())
    //   if (err.request.responseURL.indexOf('/authorize/token/refresh') === -1 && getToken() !== '') {
    //     if (isRefreshing) {
    //       return refreshPromise.then(() => http(err.config))
    //     }
    //     isRefreshing = true;
    //     refreshPromise = axios({
    //       method: 'POST',
    //       url: `${baseURL}/authorize/token/refresh?refreshToken=${getRefreshToken()}`,
    //       headers: {
    //         Authorization: getToken(),
    //       }
    //     }).then(({ data }: any) => {
    //       isRefreshing = false;
    //       // let maxAge = 8 * 60 * 60;
    //       document.cookie = `accessToken=${data.accessToken}; path=/`;
    //       document.cookie = `refreshToken=${data.refreshToken}; path=/`;
    //       localStorage.setItem('expirationTime', data.expiration)
    //     }).catch(() => {
    //       isRefreshing = false;
    //       document.cookie = `accessToken=; path=/`;
    //       document.cookie = `refreshToken=; path=/`;
    //     });
    //     return refreshPromise.then(() => http(err.config))
    //   } else {
        ElMessage.error('您的会话已过期，请重新登录');
        router.push('/auth/login');
        return Promise.reject(err);

    //   }
    } else {
      ElMessage.error(err.response.data.detail);
      return Promise.reject(err);
    }
  },
);

export { baseURL };

export default http;
