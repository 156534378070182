import { RouteRecordRaw } from 'vue-router';

const AccreditationRoutes: RouteRecordRaw[] = [
  {
    path: '/accredit',
    component: () => import('@/components/accreditation/AccreditationView.vue'),
    meta: { auth: ['ROLE_USER'], group: 'user', menuGroup: 'user' },
  },
];

export default AccreditationRoutes;
