<template>
  <div class="flex-col-end menu-box">
    <div
      v-for="(item, index) in menuList"
      :key="index"
      class="item-box"
      :class="curGroup === item.group ? 'unfold' : ''"
    >
      <div
        class="item-text-box flex-vcenter flex-jcsb pointer"
        @click="setGroup(item.group)"
      >
        <div class="flex-vcenter">
          <img v-show="curGroup !== item.group" :src="item.icon" alt="" />
          <img v-show="curGroup === item.group" :src="item.iconSel" alt="" />
          <div class="item-text">{{ item.name }}</div>
        </div>
        <img :src="ArrowRight" alt="" class="arrow" />
      </div>
      <div class="flex-jcsb sub-box">
        <div class="decor-vline"></div>
        <div>
          <div
            v-for="(sub, index) in item.children"
            :key="index"
            class="sub-item flex-vcenter pointer"
            :class="sub.paths.includes(curPath) ? 'selected' : ''"
            @click="routeTo(sub.paths[0])"
          >
            {{ sub.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="contactFixRightCon">
      <div class="contactFixRight">
        <div class="contactCon">
          <img src="./contact.svg" alt="申报咨询" title="申报咨询" class="contact" />
        </div>
      </div>
      <div class="organStruct">
          <!-- <h3>申报咨询</h3> -->
          <div>
            <div>
              <h4>中国信息通信研究院</h4>
              <div class="nameIco">冯天宜 </div>
              <div class="telIco" style="white-space: nowrap;margin-bottom: 6px;">18810626607</div>
              <div class="nameIco" style="margin-bottom: 6px;">武雅文 </div>
              <div class="telIco">18310943365</div>
            </div>
            <div style="margin-left: 50px;">
              <h4>工业互联网创新中心</h4>
              <div class="nameIco">孙圳</div>
              <div class="telIco">021-68866370</div>
            </div>
          </div>
      </div>
    </div>
    
    
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getUserRole } from '@/utils';
import menu from './menu';
import ArrowRight from '@/assets/arrow-right.svg';
export default defineComponent({
  name: 'Menu',
  setup(props) {
    return { menu, ArrowRight, role: getUserRole() };
  },
  props: {
    auth: { type: String, require: true },
  },
  data() {
    return {
      curGroup: 'proj-apls',
    };
  },
  computed: {
    menuList() {
      const curRole = this.role;
      return this.menu
        .filter((item) => item.role.includes(curRole))
        .map((item) => {
          return {
            ...item,
            children: [
              ...item.children.filter(
                (sub: any) =>
                  sub.role === undefined || sub.role.includes(curRole),
              ),
            ],
          };
        });
    },
    curPath() {
      return this.$route.matched.slice(-1)[0].path;
    },
  },
  methods: {
    setGroup(group: string) {
      this.curGroup = group;
    },
    routeTo(path: string) {
      this.$router.push(path.replace(':page', '1'));
    },
  },
  created() {
    this.curGroup = this.$route.meta.menuGroup as string;
  },
});
</script>

<style scoped>
.menu-box {
  height: 100%;
  background-color: #fff;
  box-shadow: 6px 0px 30px 0px rgba(20, 49, 152, 0.08);
}
.item-box {
  width: 280px;
  height: 60px;
  border-radius: 20px 0px 0px 20px;
  overflow: hidden;
}
.item-box.unfold {
  background: rgba(245, 246, 250, 0.5);
  height: initial;
}
.item-text-box {
  height: 60px;
  padding: 0 30px;
  font-size: 16px;
  color: #2c384d;
}
.unfold .item-text-box {
  color: #0077ff;
}
.item-text {
  margin-left: 20px;
}
.arrow {
  transition: transform 0.4s;
}
.unfold .arrow {
  transform: rotate(90deg);
}
.sub-box {
  margin-bottom: 20px;
  margin-left: 40px;
}
.decor-vline {
  width: 1px;
  margin: 14px 0;
  background: #d8dce5;
}
.sub-item {
  box-sizing: border-box;
  width: 220px;
  height: 44px;
  font-size: 14px;
  color: #798ba2;
  border-radius: 10px 0px 0px 10px;
  padding-left: 14px;
}
.sub-item.selected {
  color: #2c384d;
  background: #f5f6fa;
}
.organStruct {
  margin-top: auto;
  /* width: 100%; */
  padding: 30px;
  box-sizing: border-box;
  /* margin-bottom: 20px; */
  position: fixed;
  right: 90px;
  bottom: 100px;
  /* background-image: linear-gradient(0deg, #ffffff 0%, #f3f5f8 100%); */
  border: 1px solid #eeeeee74;
  box-shadow: 0px 6px 20px 0 rgba(0,0,0,.05);
  border-radius: 4px;
  /* background-color: #e1e4e9; */
  display: none;
  z-index: 9;
  background: #fff;
}
.organStruct:hover {
  box-shadow: 0 4px 8px 0 rgba(3,27,78,.13);
  transition: all .2s linear;
}

.organStruct h3 {
  margin: 24px auto 14px;
  text-align: left;
  color: #333333;
  line-height: 25px;
  font-weight: bold;
  font-size:22px;
  position: relative;
}
/* .organStruct h3::after {
    content: ' ';
    position: absolute;
    width: 64px;
    height: 12px;
    background: url(/src/assets/first-page/h3.png) 0 0 / cover;
    top: 50%;
    margin-top: -6px;
    margin-left: 14px;
} */
.organStruct h4 {
    margin-bottom: 19px;
    margin-top: 0px;
    color: #333333;
    line-height: 22px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 18px;
}
.organStruct>div {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  color: #666666;
  line-height: 22px;
} 
.nameIco {
    padding: 0 0 0 26px;
    height: auto;
    overflow: hidden;
    position: relative;
    margin-bottom: 6px;
}
.nameIco::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-image: url(/src/assets/first-page/nameIco.png);
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 2px;
}
.telIco {
    padding: 0 0 0 26px;
    height: auto;
    overflow: hidden;
    position: relative;
    margin-bottom: 6px;
}
.telIco::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-image: url(/src/assets/first-page/telIco.png);
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 2px;
}
.contactFixRightCon:hover .organStruct {
  display: block;
}
.contactFixRight {
  position: fixed;
  right: 10px;
  bottom: 80px;
  padding: 20px;
  z-index: 9;
}
.contactCon {
  padding: 12px;
  border-radius: calc(26px * var(--qcloud-float-ratio));
  /* background-image: linear-gradient(0deg, #ffffff 0%, #f3f5f8 100%); */
  box-shadow: 0px 4px 10px 0 rgba(55,99,170,.1);
  border: 1px solid #0b7af138;
  background-clip: padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
  width: 52px;
  height: 52px;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
}
.contact {
  width: 24px;
  height: 24px;
}
.contactCon:hover {
  /* box-shadow: 8px 8px 20px rgba(55,99,170,.3); */
}
</style>
