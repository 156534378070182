<template>
  <div class="header flex-jcsb">
    <!-- logo -->
    <div class="flex-vcenter pointer" @click="routeTo()">
      <!-- <img src="@/assets/logo.png" alt="CAICT" class="CAICT" /> -->
      <!-- <div class="logo-split-line"></div> -->
      <!-- yqq注释 -->
<!--      <img src="./logoq.png" alt="logo" class="logo" />-->
      <div class="title">{{ name }}</div>
    </div>
    <div class="flex-vcenter">
      <!-- <el-popover placement="bottom" :width="312" trigger="hover">
        <template #reference>
          <div v-show="role === 'ROLE_USER'" class="menu pointer">
            <img src="./header-menu-icon.svg" alt="" />
            <span class="text">项目申报</span>
          </div>
        </template>
        <div class="menu-content-box">
          <div class="group-title">国家项目申报</div>
          <div class="proj-title pointer" @click="routeTo()">
            人工智能医疗器械创新任务揭榜
          </div>
        </div>
      </el-popover> -->
      <!-- <a
        :href="operation_manual_url"
        target="_blank"
        style="text-decoration: none"
        class="flex-vcenter pointer yhsc-box"
        v-if="
          role === 'ROLE_USER' ||
          role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
          role === 'ROLE_INSTITUTION_ADMIN' ||
          role === 'ROLE_INSTITUTION_USER'
        "
      >
        <img class="yhsc-i" src="./header-menu-icon.png" alt="" />
        <span class="yhsc-t">使用手册</span>
      </a> -->
      <div class="split-line"></div>
      <div class="user-box flex-vcenter">
        <el-popover placement="bottom" :width="136" trigger="hover">
          <template #reference>
            <div class="flex-vcenter pointer">
              <img
                :src="url ? url : DefalutImage"
                style="width: 48px; height: 48px; border-radius: 50%"
                alt=""
              />
              <div class="pointer" style="margin-left: 20px">
                <span>{{ username }}</span>
              </div>
              <img
                src="@/assets/arrow-down.svg"
                alt="arrow-down"
                style="margin-left: 10px"
              />
            </div>
          </template>
          <div class="user-content-box">
            <div
              v-show="
                role === 'ROLE_USER' ||
                role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
                 role === 'ROLE_INSTITUTION_ADMIN' ||
                role === 'ROLE_EXPERT' ||
                role === 'ROLE_INSTITUTION_USER'
              "
              class="menu-item pointer"
              @click="routeTo('userinfo')"
            >
              用户中心
            </div>
            <div
              class="menu-item pointer"
              style="margin-top: 4px"
              @click="routeTo('login')"
            >
              退出登录
            </div>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getUserRole } from '@/utils';
import DefalutImage from '@/assets/user-icon.png';
import { aimdUrlArr, aimdName, aingName } from '@/store/config';
import { download_files } from '@/store/config';

export default defineComponent({
  name: 'Header',
  props: {
    username: { type: String, default: '' },
    url: { type: String, default: '' },
  },
  data() {
    return {
      role: getUserRole(),
      DefalutImage,
      name: aimdName,
      operation_manual_url: download_files.operation_manual_company,
    };
  },
  created() {
    if (aimdUrlArr.indexOf(window.location.host) > -1) {
      this.name = aimdName;
    } else {
      this.name = aingName;
    }
    if (
      this.role == 'ROLE_INSTITUTION_ADMIN_LOWER' ||
      this.role == 'ROLE_INSTITUTION_ADMIN' ||
      this.role == 'ROLE_INSTITUTION_USER'
    ) {
      this.operation_manual_url =
        download_files.operation_manual_competent_unit;
    }
  },
  methods: {
    routeTo(page: string = '') {
      switch (page) {
        case 'userinfo':
          this.$router.push('/user/info');
          break;
        case 'login':
          this.$router.push('/auth/login');
          break;
        default:
          this.$router.push('/');
          break;
      }
    },
  },
});
</script>

<style scoped>
.CAICT{width:98px;height: auto;}
.header {
  min-width: 1100px;
  height: 100px;
  background-color: #fff;
  padding: 0 30px;
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: #023A7B;
  letter-spacing: 1px;
  margin-left: 12px;
}
.menu > .text {
  font-size: 18px;
  color: #2c384d;
  margin-left: 10px;
}
.menu-content-box {
  padding: 30px;
}
.menu-content-box > .group-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.proj-title {
  font-size: 14px;
  color: #606266;
  margin-top: 20px;
}
.proj-title:hover {
  color: #0077ff;
}
.logo-split-line {
  width: 1px;
  height: 32px;
  background: #d8dce5;
  margin: 0 30px;
}
.split-line {
  width: 1px;
  height: 100px;
  background: #f5f6fa;
  margin: 0 50px;
}
.user-content-box {
  padding: 4px 0;
}
.menu-item {
  padding: 10px 20px;
  font-size: 14px;
}
.menu-item:hover {
  background-color: #eee;
   font-size: 14px;
}
.logo {
  width: 158px;
  height:60px;
}
.yhsc-t {
  font-size: 16px;
  color: #606266;
  line-height: 22px;
  margin-left: 10px;
}
.yhsc-i {
  width: 16px;
  height: 16px;
}
.yhsc-box:hover .yhsc-i {
  content: url('../header/header-menu-icon-s.png');
}
.yhsc-box:hover .yhsc-t {
  color: #0077ff;
}
</style>
