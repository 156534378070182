<template>
  <div class="posr body">
    <UnauthHeader />
    <div class="poser bg-head">
      <div class="pointer posa btn" @click="jump()"></div>
    </div>
    <div class="pro-se-box flex-hcenter">
      <div class="flex-col">
        <div class="flex-row" style="width: 1200px; margin-bottom: 60px">
          <div class="pro-se-title">项目背景</div>
          <div class="pro-se-e-title">PROJECT BACKGROUND</div>
          <img src="@/assets/home/dot-right.svg" style="margin-top: 10px" alt=""/>
        </div>
        <div class="pro-se-bg-title">
          为贯彻落实习近平总书记关于有力有序推进创新攻关“揭榜挂帅”体制机制的重要指示精神和
          《人工智能产业创新发展行动计划（2021-2023年）》（工信部科〔XX〕XX号）要求，持
          续推动我国新一代人工智能产业创新发展，制定本方案。
        </div>
        <div class="flex-row" style="margin-top: 36px">
          <img src="@/assets/home/book.svg" style="margin-right: 8px" alt=""/>
          <div style="font-size: 12px; color: #99a4b2; line-height: 20px">
            2021年新一代人工智能产业创新重点任务（技术产品创新）揭榜工作方案.docx
          </div>
          <img
            src="@/assets/home/downloads.png"
            style="margin-left: 10px; width: 16px; height: 16px"
            alt=""/>
          <div style="font-size: 14px; color: #0077ff; margin-left: 4px">
            点击下载
          </div>
        </div>
      </div>
    </div>
    <div class="pro-se-box" style="height: 337px; background: #f5faff">
      <div class="posr flex-hcenter">
        <img
          class="posa"
          src="@/assets/home/bgs1.png"
          style="width: 536px; height: 532px; left: 0px; top: -185px"
          alt=""/>
        <div class="flex-row" style="width: 1200px; margin-top: 80px">
          <div class="flex-col">
            <div class="flex-row" style="margin-bottom: 60px">
              <div class="pro-se-title">工作目标</div>
              <div class="pro-se-e-title">WORK OBJECTIVES</div>
              <img src="@/assets/home/dot-right.svg" style="margin-top: 10px" alt=""/>
            </div>
            <div class="pro-se-bg-title">
              聚焦“培育智能产品、突破核心基础、深化发展智能制造、构建支撑体系”等重点方向，征集并初评一批掌握关键核心技术、具备较强创新能力的单位集中攻关，重点突破一批技术先进、性能优秀、应用效果好的人工智能标志性产品、平台和服务，为产业界创新发展树立标杆和方向，培育我国人工智能产业创新发展的主力军。
            </div>
          </div>
          <img
            src="@/assets/home/work.png"
            style="
              width: 596px;
              height: 497px;
              margin-top: -330px;
              margin-left: 32px;
            "
          alt=""/>
        </div>
      </div>
    </div>
    <div
      class="posr flex-hcenter"
      style="padding-top: 80px; width: 100%; min-width: 1200px"
    >
      <img
        class="posa"
        src="@/assets/home/bgs2.png"
        :style="`width: 34.69%; height: auto; right: 0px; top: ${
          isDisabledF ? '480px' : '240px'
        }`"
      alt=""/>
      <div style="width: 1200px">
        <div class="flex-row">
          <div class="pro-se-title">申请范围</div>
          <div class="pro-se-e-title">APPLICATION SCOPE</div>
          <img src="@/assets/home/dot-right.svg" style="margin-top: 10px" alt=""/>
        </div>
        <div class="flex-row" style="margin: 60px 0px">
          <div class="pro-se-t">核心基础</div>
          <div class="pro-se-t-l"></div>
        </div>
        <div class="flex-row">
          <div class="flex-col" style="margin-right: 150px">
            <img class="pro-se-icon" src="@/assets/home/icon1.png" alt=""/>
            <div class="pro-se-t-bx">高性能云端人工智能芯片</div>
            <div class="pro-se-bg-title" style="width: 300px">
              研制高性能云端人工智能芯片，突破适用于人工智能计算范式的矩阵乘加内核架构、实现高速互联总线等核心技术，满足云计算环境中的低能耗训练和推理。在智慧城市、自动驾驶、云计算、智能家居等重点领域实现规模化商用。
            </div>
          </div>
          <div class="flex-col" style="margin-right: 150px">
            <img class="pro-se-icon" src="@/assets/home/icon2.png" alt=""/>
            <div class="pro-se-t-bx">高性能边缘端/终端计算人工智能芯片</div>
            <div class="pro-se-bg-title" style="width: 300px">
              面向机器学习边缘端及终端，研发高性能、低功耗、低延时、高算力性价比的人工智能芯片；研发配套的编译器、驱动软件、开发环境等产业化支持工具，形成加速卡、智能计算盒子、边缘服务器等完整的配套产品。
            </div>
          </div>
          <div class="flex-col">
            <img class="pro-se-icon" src="@/assets/home/icon3.png" alt=""/>
            <div class="pro-se-t-bx">智能传感器</div>
            <div class="pro-se-bg-title" style="width: 300px">
              研发基于新需求、新材料、新工艺、新原理的智能传感器，提升图像、声学、健康监测、车规级雷达、车规级摄像头等智能传感器自主研发水平，推动智能传感器的产业化应用。
            </div>
          </div>
        </div>
        <div v-show="isDisabledF" class="flex-row" style="margin-top: 60px">
          <div class="flex-col" style="margin-right: 150px">
            <img class="pro-se-icon" src="@/assets/home/icon4.png" alt=""/>
            <div class="pro-se-t-bx">终端人工智能推断框架</div>
            <div class="pro-se-bg-title" style="width: 300px">
              开发高性能终端人工智能推断框架，突破多模式训练、多精度推理、多平台覆盖、模型量化等关键技术，运行效率、量化能力、压缩率满足应用场景需求，实现自学习、自定义算子、分布式算力调度等能力。
            </div>
          </div>
          <div class="flex-col" style="margin-right: 150px">
            <img class="pro-se-icon" src="@/assets/home/icon5.png" alt=""/>
            <div class="pro-se-t-bx">人工智能开发服务平台及工具</div>
            <div class="pro-se-bg-title" style="width: 300px">
              研制低门槛、高性能、可扩展的人工智能开发平台，突破智能数据标注、自动机器学习（AutoML）、大规模异构资源管理、云边端协同管理等核心技术，提供面向机器视觉、自然语言处理等特定应用和金融、制造、能源等典型行业的平台服务能力。
            </div>
          </div>
        </div>
        <div class="flex-hcenter">
          <div class="pro-se-s-box pointer" @click="openF()">
            <div class="pro-se-s-l-box flex-row flex-hcenter flex-vcenter">
              <img
                :src="!isDisabledF ? ZKIcon : SQIcon"
                style="width: 12px; height: 12px; margin-right: 8px"
                alt=""/>
              <div>{{ !isDisabledF ? '展开' : '收起' }}</div>
            </div>
          </div>
        </div>
        <div class="flex-row" style="margin: 60px 0px">
          <div class="pro-se-t">智能产品</div>
          <div class="pro-se-t-l"></div>
        </div>
        <div class="flex-row">
          <div class="flex-col" style="margin-right: 150px">
            <img class="pro-se-icon" src="@/assets/home/icon6.png" alt=""/>
            <div class="pro-se-t-bx">机器翻译系统</div>
            <div class="pro-se-bg-title" style="width: 300px">
              突破低资源机器翻译模型架构、跨语言跨领域知识迁移、鲁棒性训练与解码、多语言通用翻译引擎等核心技术，开发高性能的小语种自动翻译模型与算法。在实时、非实时、常见噪声等多种应用场景下，支持语音转文本、语音转语音、文本转语音、文本转文本等能力。
            </div>
          </div>
          <div class="flex-col" style="margin-right: 150px">
            <img class="pro-se-icon" src="@/assets/home/icon7.png" alt=""/>
            <div class="pro-se-t-bx">三维图像身份识别系统</div>
            <div class="pro-se-bg-title" style="width: 300px">
              研发三维图像身份识别系统，包括3D成像硬件模组，千万大库3D人脸识别算法，云-边协同3D人脸识别引擎等关键技术，实现在人脸支付、智慧安检、视频监控、图像检索等典型场景的应用。
            </div>
          </div>
          <div class="flex-col">
            <img class="pro-se-icon" src="@/assets/home/icon8.png" alt=""/>
            <div class="pro-se-t-bx">智能语音交互系统</div>
            <div class="pro-se-bg-title" style="width: 300px">
              研究基于人机对话的智能语音交互系统，突破环境因素和用户口语发音差异等导致的语音识别技术瓶颈。研究多语种及多风格情感语音合成技术，实现自然、情感丰富的语音合成效果。研究以多模态识别技术为前端，基于多种机器学习方法的语义对话系统，提升开放场景下的语义泛化能力。研究智能语音分布式管理，实现多个智能交互设备的协同工作。在智能制造、智能客服、智能车载、智能家居等场景下实现大规模应用。
            </div>
          </div>
        </div>
        <div class="flex-col" v-show="isDisabledS">
          <div class="flex-row" style="margin-top: 60px">
            <div class="flex-col" style="margin-right: 150px">
              <img class="pro-se-icon" src="@/assets/home/icon9.png" alt=""/>
              <div class="pro-se-t-bx">自动驾驶虚拟仿真测试平台</div>
              <div class="pro-se-bg-title" style="width: 300px">
                研制高置信度、高覆盖度、高精度的自动驾驶仿真测试验证平台，突破场景构建、车辆动力学建模、驾驶员建模、传感器建模等关键技术，提升自动驾驶系统功能测试和性能评价能力，验证自动驾驶系统是否符合应用功能要求和安全要求。
              </div>
            </div>
            <div class="flex-col" style="margin-right: 150px">
              <img class="pro-se-icon" src="@/assets/home/icon10.png" alt=""/>
              <div class="pro-se-t-bx">智能机器人</div>
              <div class="pro-se-bg-title" style="width: 300px">
                重点围绕家庭服务、医疗健康、公共服务、养老服务、金融服务、巡检安监、智能物流等领域，突破包括多模态智能交互、多机协同及云平台、智能精准安全操控、感知信息融合、影像定位与导航等关键技术，推进智能机器人规模商用。
              </div>
            </div>
            <div class="flex-col">
              <img class="pro-se-icon" src="@/assets/home/icon11.png" alt=""/>
              <div class="pro-se-t-bx">智能无人机</div>
              <div class="pro-se-bg-title" style="width: 300px">
                突破智能跟随、自主作业、群体协同作业等关键技术，推动5G通信、北斗导航、边缘计算等新技术在数据传输、链路控制、智能操作、监控管理等方面的应用。促进智能无人机在应急救援、通信保障、电力巡检、森林防控、采矿安监等危特场景的应用。
              </div>
            </div>
          </div>
          <div class="flex-row" style="margin-top: 60px">
            <div class="flex-col" style="margin-right: 150px">
              <img class="pro-se-icon" src="@/assets/home/icon12.png" alt=""/>
              <div class="pro-se-t-bx">智能导盲产品</div>
              <div class="pro-se-bg-title" style="width: 300px">
                围绕视障人群的无障碍独立出行需求，研制具有高性能、高精度、高度无障碍的导盲系统及产品，突破室内精准无障碍导航、室外复杂环境精准导盲、复杂场景下智能感知、自主决策、协同引导以及智能信息共享等关键技术，支持立体空间安全避障，提升路径学习、物品识别的自学习能力，进一步解决视障人群的出行问题。
              </div>
            </div>
            <div class="flex-col" style="margin-right: 150px">
              <img class="pro-se-icon" src="@/assets/home/icon13.png" alt=""/>
              <div class="pro-se-t-bx">智能制造关键技术装备与系统</div>
              <div class="pro-se-bg-title" style="width: 300px">
                突破智能装备自主识别、自主优化、自主学习、群体协同等关键技术，推动人工智能技术与智能制造装备融合。研发智能新型工业控制系统等创新产品，推进人工智能算法与工业自动化系统融合。研发智能工业软件，推进人工智能与研发设计、生产管控、经营管理等工业软件系统的融合与应用。
              </div>
            </div>
            <div class="flex-col">
              <img class="pro-se-icon" src="@/assets/home/icon14.png" alt=""/>
              <div class="pro-se-t-bx">高精度工业视觉检测系统</div>
              <div class="pro-se-bg-title" style="width: 300px">
                研制基于机器视觉、高精度传感等技术的工业视觉检测系统，推动视觉和人工智能技术结合的检测系统在精度、稳定性与检测速度等领域关键技术突破，实现视觉技术在测量、定位、检测、引导及识别等生产管理重点领域的场景创新与推广应用。
              </div>
            </div>
          </div>
        </div>
        <div class="flex-hcenter">
          <div class="pro-se-s-box pointer" @click="openS()">
            <div class="pro-se-s-l-box flex-row flex-hcenter flex-vcenter">
              <img
                :src="!isDisabledS ? ZKIcon : SQIcon"
                style="width: 12px; height: 12px; margin-right: 8px"
                alt=""/>
              <div>{{ !isDisabledS ? '展开' : '收起' }}</div>
            </div>
          </div>
        </div>
        <div class="flex-row" style="margin: 60px 0px">
          <div class="pro-se-t">公共支撑</div>
          <div class="pro-se-t-l"></div>
        </div>
        <div class="flex-row">
          <div class="flex-col" style="margin-right: 150px">
            <img class="pro-se-icon" src="@/assets/home/icon15.png" alt=""/>
            <div class="pro-se-t-bx">人工智能训练资源库</div>
            <div class="pro-se-bg-title" style="width: 300px">
              建设通用基础训练资源库和行业训练资源库，可提供合规的、高质量人工智能训练资源库、标准测试数据和服务能力，具备多类型、多场景数据采集与处理服务能力。通用基础训练资源库支持计算机视觉、智能语音、自然语言处理等典型人工智能应用训练数据，行业训练资源库可提供定制化行业领域训练数据服务。
            </div>
          </div>
          <div class="flex-col" style="margin-right: 150px">
            <img class="pro-se-icon" src="@/assets/home/icon16.png" alt=""/>
            <div class="pro-se-t-bx">大规模预训练模型</div>
            <div class="pro-se-bg-title" style="width: 300px">
              研发面向计算机视觉、自然语言处理、智能语音等人工智能核心技术的大规模预训练模型。突破预训练模型的训练算力、时间等限制，结合微调等技术，提升常见视觉、语言任务的分析和处理效果，搭建人工智能通用算法底座，提升大规模预训练模型的公共支撑能力。
            </div>
          </div>
          <div class="flex-col">
            <img class="pro-se-icon" src="@/assets/home/icon17.png" alt=""/>
            <div class="pro-se-t-bx">人工智能安全检测平台</div>
            <div class="pro-se-bg-title" style="width: 300px">
              研发人工智能数据安全测试平台，支持对模型数据泄露行为检测。研发人工智能算法安全性测评平台，支持针对以人脸识别身份认证、自动驾驶智能识别等为代表的人工智能系统进行抗对抗样本攻击能力等安全风险的测评。研发面向金融、政务、电商等行业领域的风险监测预警平台。
            </div>
          </div>
        </div>
        <div class="flex-row" style="margin: 60px 0px">
          <div class="pro-se-t">其他</div>
          <div class="pro-se-t-l"></div>
        </div>
        <div class="flex-col" style="margin-bottom: 110px">
          <img class="pro-se-icon" src="@/assets/home/icon18.png" alt=""/>
          <div class="pro-se-t-bx">
            人工智能产业发展涉及的其他重要技术、产品、服务和平台等
          </div>
          <div class="pro-se-bg-title" style="width: 300px">
            人工智能产业发展涉及的其他领域，具有一定的技术先进性，技术成熟度较高，产业化前景较好的重要技术、产品、服务和平台等。
          </div>
        </div>
      </div>
      <img
        class="posa"
        src="@/assets/home/bgss.png"
        style="width: 70.83%; height: auto; right: 0px; bottom: 0px"
        alt=""
      />
    </div>
    <div class="pro-se-back" style="padding: 80px 0px">
      <div class="flex-col">
        <div class="flex-hcenter">
          <div class="flex-row" style="width: 1200px">
            <div class="pro-se-title" style="color: white">申请要求</div>
            <div class="pro-se-e-title" style="color: #6095ff; opacity: 1">
              APPLICATION REQUIREMENTS
            </div>
            <img src="@/assets/home/dot-right.svg" style="margin-top: 10px" alt=""/>
          </div>
        </div>
        <div class="flex-hcenter">
          <div style="width: 1200px">
            <div
              v-for="(item, index) in reqData"
              :key="index"
              style="margin-top: 20px"
            >
              <CustomRequire :item="item"></CustomRequire>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-hcenter pre-se-org" style="margin-top: 80px">
      <div class="flex-col">
        <div class="flex-row" style="width: 1200px">
          <div class="pro-se-title">组织与安排</div>
          <div class="pro-se-e-title">ORGANIZATION AND ARRANGEMENT</div>
          <img src="@/assets/home/dot-right.svg" style="margin-top: 10px" alt="" />
        </div>
        <div class="flex-row" style="margin: 60px 0px 0px 0px">
          <div class="pro-se-t">工作流程</div>
          <div class="pro-se-t-l"></div>
        </div>
        <div class="flex-row">
          <div v-for="(item, index) in wData" :key="index">
            <div class="flex-row">
              <CustomWorkflow :item="item"></CustomWorkflow>
              <img
                v-if="index < 4"
                class="arrows"
                src="@/assets/home/arrows.svg"
                alt=""/>
            </div>
          </div>
        </div>
        <div class="flex-row" style="margin: 60px 0px">
          <div class="pro-se-t">申报日期</div>
          <div class="pro-se-t-l"></div>
        </div>
        <div class="flex-row">
          <img src="@/assets/home/date.svg" class="date-img" alt=""/>
          <div class="flex-col">
            <div class="date-title">2021年XX月XX日-XX日（9：00-17：00）</div>
            <div class="date-desc">逾期不予受理</div>
          </div>
        </div>
        <div class="flex-row" style="margin: 60px 0px">
          <div class="pro-se-t">组织架构</div>
          <div class="pro-se-t-l"></div>
        </div>
        <div class="flex-row zj-view">
          <div class="flex-col" style="width: 300px">
            <div class="zj-title">组织单位</div>
            <div>工业和信息化部</div>
          </div>
          <div class="flex-col" style="width: 300px; margin-left: 150px">
            <div class="zj-title">支撑单位</div>
            <div>中国信息通信研究院</div>
          </div>
          <div class="flex-col" style="width: 300px; margin-left: 150px">
            <div class="zj-title">技术支持</div>
            <div>泰尔终端实验室</div>
            <div>工业互联网创新中心（上海）有限公司</div>
          </div>
        </div>
      </div>
    </div>
    <div class="posr bg-line">
      <div class="flex-hcenter">
        <div class="flex-row" style="width: 1200px; margin-top: 270px">
          <div class="pro-se-title">申报咨询</div>
          <div class="pro-se-e-title">APPLICATION CONSULTATION</div>
          <img src="@/assets/home/dot-right.svg" style="margin-top: 10px" alt=""/>
        </div>
      </div>
    </div>
    <div class="refer-view flex-hcenter flex-vcenter flex-col">
      <div class="refer flex-row">
        <div class="flex-col">
          <div class="depar">工业和信息化部科技司</div>
          <div class="flex-row">
            <img src="@/assets/home/person.svg" alt=""/>
            <div class="refer-name">张晓峰</div>
          </div>
          <div class="flex-row" style="margin-top: 10px">
            <img src="@/assets/home/phone.svg" alt=""/>
            <div class="refer-name">18512342011</div>
          </div>
        </div>
        <div class="flex-col" style="margin-left: 120px">
          <div class="depar">中国信息通信研究院</div>
          <div class="flex-row">
            <img src="@/assets/home/person.svg" alt=""/>
            <div class="refer-name">吴文杰</div>
          </div>
          <div class="flex-row" style="margin-top: 10px">
            <img src="@/assets/home/phone.svg" alt=""/>
            <div class="refer-name">18512342011</div>
          </div>
        </div>
        <div class="flex-col" style="margin-left: 120px">
          <div class="depar">
            第一批慢性病防治典型数字产品与服务征集平台技术支持
            <!-- 新一代人工智能产业创新重点任务揭榜申报服务平台技术支持 -->
          </div>
          <div class="flex-row">
            <img src="@/assets/home/person.svg" alt=""/>
            <div class="refer-name">耿飞</div>
          </div>
          <div class="flex-row" style="margin-top: 10px">
            <img src="@/assets/home/phone.svg" alt=""/>
            <div class="refer-name">18512342011</div>
          </div>
        </div>
      </div>
      <div class="refer-bg"></div>
    </div>
    <div class="footer-view">
      <div class="flex-hcenter flex-vcenter">
        <div class="footer">
          <div class="lxwm">联系我们</div>
          <div
            style="
              width: 26px;
              height: 2px;
              background-color: white;
              margin-bottom: 40px;
            "
          ></div>
          <div class="footer-t flex-row">
            <div class="flex-col">
              <div class="f-depar">中国信通院</div>
              <div class="flex-row">
                <img src="@/assets/home/person.svg" alt=""/>
                <div class="f-name">刘老师</div>
              </div>
              <div class="flex-row" style="margin-top: 10px">
                <img src="@/assets/home/phone.svg" alt=""/>
                <div class="f-name">18518061812</div>
              </div>
              <div class="flex-row" style="margin-top: 10px">
                <img src="@/assets/home/email.svg" alt=""/>
                <div class="f-name">liudong@caictyds.cn</div>
              </div>
            </div>
            <div class="flex-col" style="margin-left: 30px">
              <div class="f-depar">上海工创中心</div>
              <div class="flex-row">
                <img src="@/assets/home/person.svg" alt=""/>
                <div class="f-name">刘老师</div>
              </div>
              <div class="flex-row" style="margin-top: 10px">
                <img src="@/assets/home/phone.svg" alt=""/>
                <div class="f-name">13024102873</div>
              </div>
              <div class="flex-row" style="margin-top: 10px">
                <img src="@/assets/home/email.svg" alt=""/>
                <div class="f-name">liuna@3in.org</div>
              </div>
            </div>
            <div class="flex-col" style="margin-left: 110px">
              <div class="f-depar">组织单位</div>
              <div class="a-link pointer">工业和信息化部</div>
              <div class="a-link pointer">国家药品监督管理局</div>
            </div>
            <div class="flex-col" style="margin-left: 30px">
              <div class="f-depar">支撑单位</div>
              <div class="a-link pointer">中国信息通信研究院</div>
              <div class="a-link pointer">
                国家药品监督管理局医疗器械技术审评中心
              </div>
            </div>
            <div class="flex-col" style="margin-left: 30px">
              <div class="f-depar">技术支持</div>
              <div class="a-link pointer">
                中国信息通信研究院云计算与大数据研究所
              </div>
              <div class="a-link pointer">
                工业互联网创新中心（上海）有限公司
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UnauthHeader from '@/components/header/UnauthHeader.vue';
import CustomRequire from '@/components/home/CustomRequire.vue';
import CustomWorkflow from '@/components/home/CustomWorkflow.vue';

import Workflow1 from '@/assets/home/workflow1.svg';
import Workflow2 from '@/assets/home/workflow2.svg';
import Workflow3 from '@/assets/home/workflow3.svg';
import Workflow4 from '@/assets/home/workflow4.svg';
import Workflow5 from '@/assets/home/workflow5.svg';
import ZKIcon from '@/assets/home/zk.png';
import SQIcon from '@/assets/home/sq.png';
import { getToken, getUserRole } from '@/utils';

export default defineComponent({
  name: 'ProjectSignUpSeView',
  components: {
    UnauthHeader,
    CustomRequire,
    CustomWorkflow,
  },
  data() {
    return {
      ZKIcon,
      SQIcon,
      isDisabledF: false,
      isDisabledS: false,
      wData: [
        {
          url: Workflow1,
          number: 1,
          title: '申请揭榜',
          dTitle: '单位在线填写申请和提交申报材料',
        },
        {
          url: Workflow2,
          number: 2,
          title: '单位推荐',
          dTitle: '地方主管部门推荐优秀申报单位与项目',
        },
        {
          url: Workflow3,
          number: 3,
          title: '揭榜单位初评',
          dTitle: '组织评审初评优秀申报单位与项目',
        },
        {
          url: Workflow4,
          number: 4,
          title: '揭榜任务实施',
          dTitle: '揭榜申报任务启动与阶段检查',
        },
        {
          url: Workflow5,
          number: 5,
          title: '发布揭榜成果',
          dTitle: '荣获揭榜优秀项目成果公示',
        },
      ],
      reqData: [
        {
          title: '申报资格',
          dTitle:
            '申报单位须为在中华人民共和国境内注册、具有独立法人资格的企事业单位。',
        },
        {
          title: '申报团队',
          dTitle:
            '揭榜任务鼓励以联合体方式申报，联合体采取产学研用医相结合的方式，鼓励企业、医疗卫生机构、高校、科研院所等共同参与，牵头单位为1家，联合单位不超过5家。智能产品类揭榜任务由拟作为医疗器械注册申请人的单位牵头申请，支撑环境类揭榜任务由医疗卫生机构牵头申请。',
        },
        {
          title: '揭榜要求',
          dTitle:
            '智能产品类揭榜任务要求揭榜单位已完成产品的前期研究并具有基本定型产品，产品拥有知识产权并具有显著的临床应用价值；支撑环境类重点任务要求揭榜单位已完成前期研究并已搭建基本支撑环境。',
        },
      ],
    };
  },
  methods: {
    jump() {
      let token = getToken();
      let role = getUserRole();
      if (token && (role === 'ROLE_USER' || role === 'ROLE_PLATFORM_ADMIN')) {
        this.$router.push(`/proj-apls/center/1`);
      } else {
        this.$router.push(`/auth/login`);
      }
    },
    openF() {
      this.isDisabledF = !this.isDisabledF;
    },
    openS() {
      this.isDisabledS = !this.isDisabledS;
    },
  },
});
</script>

<style scoped>
.bg-head {
  width: 100%;
  min-width: 1200px;
  background: url('@/assets/home/pro-bg.png') no-repeat center top / cover;
  background-size: 100% 100%;
  height: 568px;
}
.btn {
  width: calc(100vw / 14);
  min-width: 83px;
  height: 44px;
  opacity: 0;
  margin-left: 67%;
  margin-top: 266px;
}
.pro-se-box {
  margin-top: 80px;
  width: 100%;
  min-width: 1200px;
}
.pro-se-title {
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  line-height: 45px;
  margin-right: 12px;
}
.pro-se-e-title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  line-height: 33px;
  opacity: 0.1;
  margin-right: 8px;
  margin-top: 10px;
}
.pro-se-bg-title {
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  width: 572px;
}
.pro-se-t {
  font-size: 22px;
  font-weight: 600;
  color: #0c2d72;
  margin-right: 8px;
}
.pro-se-t-l {
  width: 26px;
  height: 2px;
  background: #0077ff;
  margin-top: 10px;
}
.pro-se-icon {
  width: 36px;
  height: 36px;
}
.pro-se-t-bx {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  margin-top: 34px;
  margin-bottom: 18px;
}
.pro-se-back {
  width: 100%;
  min-width: 1200px;
  background: url('@/assets/home/backgr.png') no-repeat center top / cover;
  background-size: 100% 100%;
}
.arrows {
  width: 63px;
  height: 10px;
  margin-left: 38px;
  margin-right: 38px;
  margin-top: 84px;
}
.date-img {
  width: 32px;
  height: 37px;
  margin-right: 19px;
}

.date-title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}

.date-desc {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  margin-top: 8px;
}
.zj-view {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
}
.zj-title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
  margin-bottom: 18px;
}
.bg-line {
  width: 100%;
  min-width: 1200px;
  background: url('@/assets/home/bg-img.png') no-repeat center top / contain;
  background-size: 100% 100%;
  height: 356px;
  margin-top: -2px;
  z-index: -999;
}

.step-o {
  left: calc(50% - 41px);
  top: 80px;
}
.pre-se-org {
  background: linear-gradient(180deg, rgba(253, 253, 255, 0) 0%, #f9f9ff 100%);
  width: 100%;
  min-width: 1200px;
}
.refer-view {
  width: 100%;
  min-width: 1200px;
  margin-bottom: 80px;
}

.refer {
  background: linear-gradient(270deg, #0077ff 0%, #016eeb 100%);
  padding: 60px;
  width: 1080px;
}

.depar {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
  margin-bottom: 20px;
}

.refer-name {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  opacity: 0.6;
}

.refer-bg {
  background-color: #0064d7;
  width: 1080px;
  height: 20px;
}

.footer-view {
  width: 100%;
  min-width: 1200px;
  background: #33334f;
  padding-top: 50px;
  padding-bottom: 30px;
}

.footer {
  width: 1200px;
}

.footer-t {
  color: #ffffff;
}

.f-depar {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  opacity: 0.7;
  margin-bottom: 20px;
}

.f-name {
  margin-left: 8px;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
  opacity: 0.6;
}

.a-link {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  opacity: 0.6;
}

.a-link:hover {
  opacity: 1;
}

.req-top-img {
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
}

.req-left-img {
  left: 0;
  bottom: 0;
  width: 23.54%;
  object-fit: cover;
}

.hd-img {
  left: 0;
  top: -79px;
  width: 64px;
  height: 158px;
  z-index: -999;
}
.lxwm {
  font-size: 22px;
  font-weight: 600;
  color: white;
  margin-bottom: 20px;
}
.pro-se-s-box {
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(18, 128, 255, 0.14);
  padding: 5px;
  margin: 20px 0px;
}
.pro-se-s-l-box {
  width: 102px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e0efff;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC,sans-serif;
  font-weight: 600;
  color: #0077ff;
}
</style>