import {
  RouteRecordRaw,
  Router,
  createRouter,
  createWebHistory,
} from 'vue-router';

import MainView from '@/components/MainView.vue';
import NotFound from '@/components/404/NotFound.vue';
import AuthRoutes from './auth';
import ProjAplsRoutes from './proj-apls';
import ProjMgmtRoutes from './proj-mgmt';
import ProjReviewRoutes from './proj-review';
import UserRoutes from './user';
import AccreditationRoutes from './user/accreditation';
import AdminUserRoutes from './admin/ad-user';
import AdminProjectRoutes from './admin/ad-pro';
import ReviewMgmtRoutes from './admin/review-mgmt';
import HomeView from '@/components/home/HomeView.vue';
import { getUserRole, refreshToken, getRefreshToken } from '@/utils/index';
import ExpertRoutes from './expert';

const routes: RouteRecordRaw[] = [
  { path: '', component: HomeView, meta: { auth: [''] } },
  {
    path: '/',
    component: MainView,
    meta: { auth: [''] },
    children: [
      ...ProjAplsRoutes,
      ...ProjMgmtRoutes,
      ...UserRoutes,
      ...AccreditationRoutes,
      ...AdminUserRoutes,
      ...AdminProjectRoutes,
      ...ReviewMgmtRoutes,
      ...ProjReviewRoutes,

    ],
  },
  ...AuthRoutes,
  ...ExpertRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { auth: [''] },
  },
];

const router: Router = createRouter({
  history: createWebHistory(),
  routes,
});

let isRequested = false;
router.beforeEach(async (to, from) => {
  const auth = to.meta.auth as string[];
  if (!isRequested && +(localStorage.getItem('expirationTime') || Infinity) - +new Date() < 8 * 60 * 60 * 1000 && getRefreshToken()) {
    let result = await refreshToken();
    console.log('====result===>', result)
  }
  isRequested = true;
  const ROLE = getUserRole();
  return auth.includes('') || auth.includes(ROLE)
    ? true
    : { path: '/', replace: true };
});

export default router;
