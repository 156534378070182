<template>
  <div class="header flex-jcsb">
    <!-- logo -->
    <div class="flex-vcenter" @click="jump">
      <!-- yqq注释 -->
     <!-- <img src="./logo.png" alt="CAICT"  class="CAICT"/> -->
      <div class="title">{{name}}</div>
    </div>
    <div class="flex-vcenter">
      <!-- <a
        :href="operation_manual_url"
        target="_blank"
        style="text-decoration: none"
        class="flex-vcenter pointer yhsc-box"
        v-if="
          !isLogin ||
          (isLogin &&
            (role === 'ROLE_USER' ||
              role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
              role === 'ROLE_INSTITUTION_ADMIN' ||
              role === 'ROLE_INSTITUTION_USER'))
        "
      >
        <img class="yhsc-i" src="./header-menu-icon.png" alt="" />
        <span class="yhsc-t">使用手册</span>
      </a>
      <div class="split-line"></div> -->
      <div class="user-box flex-vcenter">
        <el-popover
          v-if="isLogin"
          placement="bottom"
          :width="136"
          trigger="hover"
        >
          <template #reference>
            <div class="flex-vcenter pointer">
              <img
                :src="url ? url : DefalutImage"
                style="width: 48px; height: 48px; border-radius: 50%"
                alt=""
              />
              <div class="pointer" style="margin-left: 20px">
                <span>{{ username }}</span>
              </div>
              <img
                src="@/assets/arrow-down.svg"
                alt="arrow-down"
                style="margin-left: 10px"
              />
            </div>
          </template>
          <div class="user-content-box">
            <div
              v-if="
                role === 'ROLE_USER' ||
                role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
                role === 'ROLE_INSTITUTION_ADMIN' ||
                role === 'ROLE_EXPERT' ||
                role === 'ROLE_INSTITUTION_USER'
              "
              class="menu-item pointer"
              @click="routeTo('userinfo')"
            >
              用户中心
            </div>
            <div v-else class="menu-item pointer" @click="routeTo('mgmt')">
              管理中心
            </div>
            <div
              class="menu-item pointer"
              style="margin-top: 4px"
              @click="routeTo('login')"
            >
              退出登录
            </div>
          </div>
        </el-popover>
        <div v-else class="login-box">
          <div class="login-text pointer" @click="routeTo('login')">登录</div>
          <el-button type="primary" @click="routeTo('register')" class="quitBtn">
            立即注册
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {getProfile, getUserIcon} from '@/api/api';
import { getToken, getUserRole } from '@/utils';
import DefalutImage from '@/assets/user-icon.png';
import { aimdUrlArr, aimdName, aingName } from '@/store/config';
import { download_files } from '@/store/config';

export default defineComponent({
  name: 'UnauthHeader',
  created() {
    if (aimdUrlArr.indexOf(window.location.host) > -1) {
      this.name = aimdName;
    } else {
      this.name = aingName;
    }
    if (!getToken()) {
      return;
    }
    getProfile()
      .then(({ data }: { data: any }) => {
        this.isLogin = true;
        if (
          this.isLogin &&
          (this.role == 'ROLE_INSTITUTION_ADMIN_LOWER' ||
          this.role == 'ROLE_INSTITUTION_ADMIN' ||
            this.role == 'ROLE_INSTITUTION_USER')
        ) {
          this.operation_manual_url =
            download_files.operation_manual_competent_unit;
        }
        this.username = data.name;
        if (data.avatar) {
          getUserIcon(data.avatar).then((data: any) => {
            this.url = data;
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  data() {
    return {
      DefalutImage,
      url: '',
      operation_manual_url: download_files.operation_manual_company,
      isLogin: false,
      username: '',
      role: getUserRole(),
      name: aimdName,
    };
  },
  methods: {
    jump() {
      if (this.isLogin) {
        if (this.role === 'ROLE_USER') {
          this.$router.push('/proj-apls/center/1');
        } else if (
          this.role === 'ROLE_PLATFORM_ADMIN' ||
          this.role === 'ROLE_PLATFORM_USER'
        ) {
          //平台管理员或超级管理员
          this.$router.push('/admin/user/registered/list/1');
        } else if (
          this.role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
            this.role === 'ROLE_INSTITUTION_ADMIN' ||
          this.role === 'ROLE_INSTITUTION_USER'
        ) {
          //初审单位用户或管理员
          this.$router.push('/proj-mgmt/list/1');
        } else if (this.role === 'ROLE_EXPERT') {
          this.$router.push('/proj-review');
        }
      } else {
        this.$router.push(`/auth/login`);
      }
    },
    routeTo(page: string = '') {
      switch (page) {
        case 'userinfo':
          this.$router.push('/user/info');
          break;
        case 'login':
          this.$router.push('/auth/login');
          break;
        case 'register':
          this.$router.push('/auth/register');
          break;
        case 'mgmt':

          this.$router.push('/admin/user/registered/list/1');
          break;
        default:
          this.$router.push('/');
          break;
      }
    },
  },
});
</script>

<style scoped>
.quitBtn{
height: 40px;
border-radius: 6px;
border: 1px solid #0077FF;
background-color: #fff;
font-size: 18px;
color: #0077FF;
padding: 0px !important;
}
.quitBtn:hover{
height: 40px;
border-radius: 6px;
border: 1px solid #0077FF;
background-color: #fff;
font-size: 18px;
color: #0077FF;
padding: 0px !important;
}
.pointer{font-size: 18px;color: #333333;}
.pointer:hover{font-size: 18px;color: #333333;}
.CAICT{width:98px;height: auto;}
.header {
  min-width: 1100px;
  height: 100px;
  background-color: #fff;
  padding: 0 30px;
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: #023A7B;
  letter-spacing: 1px;
  margin-left: 0px;
}
.logo-split-line {
  width: 1px;
  height: 32px;
  background: #d8dce5;
  margin: 0 30px;
}
.menu > .text {
  font-size: 18px;
  color: #2c384d;
  margin-left: 10px;
}
.menu-content-box {
  padding: 30px;
}
.menu-content-box > .group-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.proj-title {
  font-size: 14px;
  color: #606266;
  margin-top: 20px;
}
.proj-title:hover {
  color: #0077ff;
}
.split-line {
  width: 1px;
  height: 100px;
  background: #f5f6fa;
  margin: 0 50px;
}
.user-content-box {
  padding: 4px 0;
}
.menu-item {
  padding: 10px 20px;
  font-size: 14px;
}
.menu-item:hover {
  background-color: #eee;
   font-size: 14px;
}
.logo {
  width: 50px;
  height: 50px;
}
.login-box {
  display: grid;
  grid-template-columns: 70px 104px;
  align-items: center;
}

.yhsc-t {
  font-size: 16px;
  color: #606266;
  line-height: 22px;
  margin-left: 10px;
}
.yhsc-i {
  width: 16px;
  height: 16px;
}
.yhsc-box:hover .yhsc-i {
  content: url('../header/header-menu-icon-s.png');
}
.yhsc-box:hover .yhsc-t {
  color: #0077ff;
}
</style>
