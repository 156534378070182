import { RouteRecordRaw } from 'vue-router';


const AdUserRoutes: RouteRecordRaw[] = [
  {
    path: '/admin/project',
    component: () => import('@/components/admin/project-management/ProManagementView.vue'),
    meta: { auth: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'], group: 'admin-project', menuGroup: 'admin-project' },
    children: [
      {
        path: 'rank/:page',
        component: () => import('@/components/admin/project-management/ProRankView.vue'),
        meta: {
          breadcrumb: [
            { label: '评分排名' },
          ],
        },
      },
      {
        path: 'agreement/:page',
        component: () => import('@/components/admin/project-management/ReviewAgreementView.vue'),
        meta: {
          breadcrumb: [
            { label: '评审协议' },
          ],
        },
      },
      {
        path: 'launch/:page', component: () => import('@/components/admin/project-management/ProLaunchView.vue'),
        meta: {
          breadcrumb: [
            { label: '项目上架' },
          ],
        },
      },
      {
        path: 'launch/detail/:id', component: () => import('@/components/admin/project-management/ProLaunchDetailView.vue'),
        meta: {
          breadcrumb: [
            { label: '项目上架', path: '/admin/project/launch/1' },
            { label: '项目发布详情' },
          ],
        },
      },
      {
        path: 'launch/form', component: () => import('@/components/admin/project-management/ProLaunchEditorView.vue'),
        meta: {
          breadcrumb: [
            { label: '项目上架', path: '/admin/project/launch/1' },
            { label: '项目发布' },
          ],
        },
      },
      {
        path: 'mgmt',
        component: () => import('@/components/admin/project-management/ProListView.vue'),
        children: [
          { path: '', redirect: '/admin/project/mgmt/dlt/list/1' },
          {
            path: 'dlt/list/:page',
            component: () => import('@/components/admin/project-management/ProDltListView.vue'),
            meta: {
              breadcrumb: [
                { label: '项目申报列表' },
              ],
            },
          },
          {
            path: 'recommend/list/:page',
            component: () => import('@/components/admin/project-management/ProRecomView.vue'),
            meta: {
              breadcrumb: [
                { label: '项目推荐列表' },
              ],
            },
          },
        ]
      },
      {
        path: 'detail/:id', component: () => import('@/components/admin/project-management/ProDetailView.vue'),
        meta: {
          breadcrumb: [
            { label: '项目详情页' },
          ],
        },
      },
      {
        path: 'rank/:page',
        component: () => import('@/components/admin/project-management/ProRankFiles.vue'),
        meta: {
          breadcrumb: [
            { label: '评分排名' },
          ],
        },
      },

      {
        path: 'launch/files', component: () => import('@/components/admin/project-management/ProRankFiles.vue'),
        meta: {
          breadcrumb: [
            { label: '评分文件', path: '/admin/project/files/1' },
          ],
        },
      },
      {
        path: 'recommend/files', component: () => import('@/components/admin/project-management/RecommendFiled.vue'),
        meta: {
          breadcrumb: [
            { label: '推荐文件', path: '/admin/project/recommendFiles/1' },
          ],
        },
      },


    ],
  },
];

export default AdUserRoutes;
