import { RouteRecordRaw } from 'vue-router';
const AdUserRoutes: RouteRecordRaw[] = [
  {
    path: '/admin/user',
    component: () => import('@/components/admin/user-management/UserManagementView.vue'),
    meta: { auth: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'], group: 'admin-user', menuGroup: 'admin-user' },
    children: [
      { path: '', redirect: '/admin/user/registered/list' },
      {
        path: 'registered/list/:page',
        name: 'RegisteredUserView',
        component: () => import('@/components/admin/user-management/RegisteredUserView.vue'),
        meta: { breadcrumb: [{ label: '注册用户' }], isBack: false },
      },
      {
        path: 'region/list/:page',
        name: 'RegionUserView',
        component: () => import('@/components/admin/user-management/RegionUserView.vue'),
        meta: { breadcrumb: [{ label: '区域用户' }] },
      },
      {
        path: 'background/list/:page',
        name: 'AdminUserView',
        component: () => import('@/components/admin/user-management/AdminUserView.vue'),
        meta: { breadcrumb: [{ label: '后台用户' }], isBack: false },
      },
      {
        path: 'expert/list/:page',
        name: 'ExpertUserView',
        component: () => import('@/components/admin/user-management/ExpertUserView.vue'),
        meta: { breadcrumb: [{ label: '专家用户' }], isBack: false },
      },
      {
        path: 'register/detail/:id',
        name: 'RegUserDetailView',
        component: () => import('@/components/admin/user-management/RegUserDetailView.vue'),
        meta: {
          breadcrumb: [
            { label: '注册用户', path: '/admin/user/registered/list/1' },
            { label: '注册用户账号资料详情' },
          ],
        },
      },
      {
        path: 'admin/detail/:id',
        name: 'AdminUserDetailView',
        component: () => import('@/components/admin/user-management/AdminUserDetailView.vue'),
        meta: {
          breadcrumb: [
            { label: '后台用户', path: '/admin/user/background/list/1' },
            { label: '后台用户账号资料详情' },
          ],
        },
      },
      {
        path: 'expert/detail/:id',
        name: 'ExpertUserDetailView',
        component: () => import('@/components/admin/user-management/ExpertUserDetailView.vue'),
        meta: {
          breadcrumb: [
            { label: '专家用户', path: '/admin/user/expert/list/1' },
            { label: '专家用户账号资料详情' },
          ],
        },
      },
    ],
  },
];

export default AdUserRoutes;
